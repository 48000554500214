import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { formatDate } from '@/support/String';
import { parseWarning } from '@/support/Warning';
import { Report as ReportModel, Answer } from '@/models/Report';
import { Damage } from '@/models/DamageNew';
import { DamageVisibility } from '@/components/damage-panel/DamagePanel';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';

@Component<WorkDivision>({
  filters: {
    dateFormat: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date, 'dd-LL-yyyy HH:mm');
    },
  },
})
export default class WorkDivision extends Vue {
  public $pageTitle = 'Werkverdeling';

  protected reportModel: ReportModel = new ReportModel();

  protected damages: Damage[] = [];

  protected currentDamage: Damage | null = null;

  protected fileUrl ='';

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  protected isLoading = false;

  protected comment = '';

  protected damageVisibility: DamageVisibility = {
    validations: true,
    media: true,
  };

  protected generalKeys: GeneralKey = {
    SchadeadresStraat: GeneralKeys.SchadeadresStraat,
    SchadeadresHuisnummer: GeneralKeys.SchadeadresHuisnummer,
    SchadeadresHuisnummerToevoeging: GeneralKeys.SchadeadresHuisnummerToevoeging,
    SchadeadresPlaats: GeneralKeys.SchadeadresPlaats,
    SchadeadresPostcode: GeneralKeys.SchadeadresPostcode,
    DatumAanvraag: GeneralKeys.DatumAanvraag,
    BouwJaar: GeneralKeys.BouwJaar,
    EigendomSinds: GeneralKeys.EigendomSinds,
    storyOfApplicant: GeneralKeys.storyOfApplicant,
    ExpertComment: GeneralKeys.ExpertComment,
    relevantRemarksApplicant: GeneralKeys.relevantRemarksApplicant,
  };

  // approval bar
  protected approvalDialogOpen = false;

  protected approval = '';

  protected status = '';

  protected title = '';

  public mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getReport();
    await this.getDamages();
    this.isLoading = false;
  }

  protected async getReport() {
    await new ReportModel()
      .include(['applicant', 'answers', 'validations', 'reject_reasons'])
      .find(this.$route.params.id)
      .then((reportModel: ReportModel) => {
        this.reportModel = reportModel;
        if (! this.fileUrl) {
          this.fileUrl = reportModel.links.quick;
        }
        this.initBreadcrumb();
        this.setGeneralInfo();
        this.isLoading = false;
        this.isAllowedToViewDialog = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getDamages() {
    this.damages = await new Damage()
      .refactor()
      .include('validations')
      .filter({
        report: this.$route.params.id,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
          { name: 'Werkverdeling' },
        ],
      });
  }

  protected parseWarning(alert: string) {
    return parseWarning(alert);
  }

  protected close() {
    this.comment = '';
    this.approvalDialogOpen = false;
  }

  protected updateDamage() {
    if (! this.currentDamage) {
      return;
    }

    const payload = {
      status: 'rejected',
      reject_comment: this.comment,
    };

    new Damage(this.currentDamage)
      .update(payload)
      .then((response: any) => {
        this.initialize();
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected formatDateSeconds(date: string) {
    return formatDate(date, 'dd-LL-yyyy HH:mm');
  }

  protected toggleApproveDialog(status: string, title: string, approval: string) {
    this.status = status;
    this.approval = approval;
    this.title = title;
    this.approvalDialogOpen = true;
  }

  protected setGeneralInfo() {
    const keys = Object.keys(this.generalKeys);
    if (! this.reportModel.answers) {
      return;
    }

    this.reportModel.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as any)[anwser.key ? anwser.key : ''].value = anwser.value;
      }
    });
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.initialize();
  }
}
